// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.


// --------------------------------------
//               GLOBAL
// --------------------------------------

body, html {
  font-family: 'Montserrat', sans-serif;

  a {
    font-weight: 700;
    color: #ed3da5;
    transition: all 150ms ease-in-out;
    &:hover {
      color: #00bbba;
    }
  }

  .link {
    font-weight: 700;
    color: #ed3da5;
    transition: all 150ms ease-in-out;
    &:hover {
      color: #00bbba;
    }
  }
  
  .button {
    border-radius: 0;
  }

  h1:not(.hero-heading):not(.text-highlight) {
    border-bottom: 3px solid #f68e56;
    color: #000;
    display: inline-block;
    font-family: 'Roboto Slab', serif;
    font-size: 2.25em;
    font-weight: 400;
    padding-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  h1.text-highlight {
    font-family: 'Roboto Slab', serif;
    font-weight: 300 !important;
  }

  h1.text-highlight.heading2 {
    font-weight: 400;
    text-transform: uppercase;
  }

  .hero-heading {
    text-shadow: 0 0 10px rgb(0 0 0 / 70%);
  }

  h2, .h2 {
    color: #222;
    font-family: 'Roboto Slab', serif;
    font-size: 1.5625em;
    font-weight: 400;
    text-transform: uppercase;
  }

  h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: #222;
    font-family: 'Montserrat', serif;
  }
  
  h3.section-heading {
    border-bottom: 3px solid #000;
    color: #000;
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 5px;
    margin: 0 auto 40px;
    padding-bottom: 15px;
    text-transform: uppercase;

    &::before {
      display: none;
    }
  }

  .external-link-indicator {
    display: none;
  }
}

::placeholder {
  color: #000;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #000;
}

.button.active, .active.meeting-polls__button, .button:active, .meeting-polls__button:active {
  filter: brightness(100%);
}

.button.button--sc {
  background-color: #0077b5;
  color: #fff;
  display: inline-block;
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: normal;
  text-transform: none;
  font-weight: 700;
  padding: 14px 20px;
  transition: all .35s linear;
  .small {
    padding: 2px 6px;
    &:hover {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .large {
    padding-top: 14px 20px;
  }
  .button__info {
    margin-top: 0.75rem;
    strong {
      display: block;
      margin-top: 0.5rem;
    }
  }

  &.budget-list__data {
    background-color: #fff;
    border: 1px solid #0077b5;
    color: #0077b5;
    display: flex;
    padding: 0.5rem;
    
    &:hover, &:focus, &:active {
      background-color: #0077b5;
      color: #fff;
      
      .button.budget-list__action {
        border-color: #fff;
        color: #fff;
      }
    }
  }
}

.button.secondary, .button.secondary.hollow, .secondary.meeting-polls__button {
  color: #000;
  &:hover, &:focus {
    color: #000;
  }
}

.card__title {
  color: #0077b5;
  font-family: 'Roboto Slab', serif;
  font-size: 1.125em;
  font-weight: 400;
  margin-bottom: 15px;
  text-transform: uppercase;
  transition: all .35s linear;
}

.card__button.secondary {
  color: #ed3da5;
  font-weight: 700;
}

.author-data {
  &, .author__name {
    color: #0077b5;
  }
}

.omnipresent-banner {
  background-color: #ffc82b;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;

  a {
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
    transition: all .35s linear;
    color: #000;
    &:hover, &:focus, &:active {
      color: #000;
      text-decoration: underline;
    }
  }

  .omnipresent-banner-title {
    font-weight: 400;
  }
}

// --------------------------------------
//               HEADER
// --------------------------------------

.title-bar {
  background: #fff;
  color: #000;
  font-family: 'Roboto Slab', serif;
  padding: 1rem 0.5rem;
  a {
    color: #000 !important;
  }
}

@media print, screen and (min-width: 50em) {
  .logo-wrapper img {
    max-height: 120px;
  }
}

.topbar__search {
  input {
    background-color: #eee;
    color: #000;
    border-radius: 0;
    font-family: 'Roboto Slab', serif;
  }
  &:focus-within input {
    background-color: #eee;
    border: 1px solid #0077b5;
    color: #000;
  }
  button,
  .input-group-button {
    background-color: #000;
    border-radius: 0;
  }
}

.input-group > :last-child, .input-group > :last-child.input-group-button > * {
  border-radius: 0;
}

.navbar {
  background-color: #fff;

  .main-nav__link {
    a {
      box-shadow: none;
      color: #000;
      display: block;
      font-family: 'Roboto Slab', serif;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 0;
      padding: 0 2rem 2rem;
      position: relative;
      text-transform: uppercase;
      transition: all 150ms ease-in-out;

      &::after {
        background-color: #ed3da5;
        bottom: calc(1rem + 5px);
        border-radius: 999px;
        content: '';
        display: block;
        height: 6px;
        left: calc(50% - 3px);
        opacity: 0;
        position: absolute;
        width: 6px;
        z-index: 10;
      }

      &:hover, &:focus, &:active {
        outline: none;
        text-decoration: none;
        &::after {
          bottom: 1rem;
          transition: all .35s linear;
          opacity: 1;
        }
      }
    }
    &.main-nav__link--active {
      a {
        box-shadow: none;
        font-weight: 700;
        color: #0077b5;
      }
    }
  }
}

// --------------------------------------
//               FOOTER
// --------------------------------------

.main-footer, .mini-footer {
  background-color: #ffc82b;
  min-height: 76px;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  padding: 30px 0;
  position: relative;
  a {
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px;
    transition: all .35s linear;
    color: #000;
    &:hover, &:focus, &:active {
      color: #000;
      text-decoration: underline;
    }
  }
  .main-footer__badge {
    margin: 0 auto 24px;
  }
}
@media print, screen and (min-width: 40em) {
  .main-footer {
    .main__footer__nav {
      text-align: center;
    }
  }
}
@media print, screen and (min-width: 64em) {
  .main-footer {
    .main-footer__badge {
      margin: 0 10px;
    }
  }
}

// --------------------------------------
//              HOMEPAGE
// --------------------------------------

.hero::after {
  display: none;
}

body .hero__container:first-of-type .hero-cta {
  margin-top: 4rem;
  background-color: #FFFFFF;
  color: #0077b5;
  font-weight: 600;
}

.home-card__container {
  display: flex;
  gap: 32px;
  padding: 0 24px 32px;

  > img {
    object-fit: contain;
    width: 80px;
    height: 80px;
  }

  .home-card__text {
    flex: 1 1 0%;
  }
}

@media (min-width: 1024px) {
  .home-card__container {
    padding-bottom: 0; 
  }
}

// --------------------------------------
//              PROCESSES
// --------------------------------------
body {
  .process-nav__content {
    ul {
      justify-content: flex-start;
      gap: 0 16px;
    }
    li {
      padding: 1rem 0.75rem 0.75rem;
      &.is-active {
        box-shadow: inset 0 -3px 0 0 #0077b5;
      }
      .process-nav__link {
        letter-spacing: 5px;
      }
    }
  }
}  
.process-header__main {
  clip-path: none;
  max-width: 100%;

  &::after {
    display: none;
  }
}

#participatory-space-filters {
  display: none;
}

.filters {
  .new_filter {
    .filters__section.with_any_origin_check_boxes_tree_filter {
      display: none;
    }
  }
}
